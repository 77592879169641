.planCard {
    border-radius: 12px;
    background-color: #fff;
    padding: 32px;
    box-sizing: border-box;
    gap: 32px;
    min-height: 621px;
    min-width: 301px;
    max-width: 332px;
    font-family: 'Inter';

    .planIcon{
        height: 45px;
        width: auto;
    }

    h4{
        font-size: 36px;
        font-weight: 700;
        color: #53A6B2;
        margin: 12px 0px 4px 0px;
        padding-bottom: 0px;
        line-height: 100%;
    }

    .pro{
        color: #128292;
    }

    .elite{
        color: #F16722;
    }

    h6{
        font-size: 14px;
        font-weight: 600;
        color: #66707D;
        margin: 0px 0px 16px 0px;
        padding-top: 0px;
    }

    h5{
        font-size: 24px;
        font-weight: 700;
        color: #3C4654;
    }

    .planHeader{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .extraContainer{
            width: 86px;
            height: 86px;
            background-color: #53A6B2;
            border-radius: 50%;
            padding: 22px 10px 0px 10px;
    
            p{
                color: #fff;
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
            }
    
            .extraValue{
                font-weight: 700;
                font-size: 26px;
                line-height: 26px;
                margin-bottom: 0px;
                text-align: center;
            }
        }

        .proBg{background-color: #128292;}
        .eliteBg{background-color: #F16722;}
    }

    

    .saveExportDetail{
        height: 75px;
        font-weight: 600;

        p{            
            font-size: 14px;
            text-transform: uppercase;
            color: #3C4654;
            margin-bottom: 4px;
            line-height: 100%;

            img {
                margin-left: 4px;
            }
        }

        select {
            width: 132px;
            height: 30px;
            border-radius: 50px;
            padding: 0px 16px 0px 16px;
            gap: 12px;
            background-color: #128292;
            color: #fff;
            border: none;
            font-size: 14px;
        }
    }

    .monthExportSpace{
        height: 95px;
    }

    .annualExportSpace{
        height: 75px;
    }

    .planPrice{

        .priceStarts{
            font-weight: 600;
            font-size: 14px;
            color: #66707D;
            margin-bottom: 0px;

            span{
                text-decoration: line-through;
            }
        }
        
        .price{
            font-weight: 700;
            font-size: 14px;
            color: #3C4654;
            margin: 0px;

            span{
                font-size: 36px;
            }
        }

        .annual{
            font-size: 14px;
            font-weight: 500;
            color: #3C4654;
        }
    }

    button{
        border-radius: 50px;
        padding: 8px 14px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background-color: #F16722;
        border: none;
        width: 268px;
        height: 36px;
        margin: 10px 0px 30px 0px;
    }

    ul{
        list-style: none;
        padding-left: 0px;

        li{
            font-size: 14px;
            line-height: 18px;
            margin: 16px 0px;
            font-weight: 500;
    
            padding-left: 16px;
    
            b{
                font-weight: 800;
            }

            img{
                margin-left: 8px;
            }
            
        }
    
        li:before{
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            margin-left: -14px;
            background-position: 0px 5px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.12793' width='8' height='8' rx='7' fill='%23128292'/%3E%3C/svg%3E");
        }
    }

    ul.annual{
        li:last-child:before{
            background-image: url("data:image/svg+xml,%3Csvg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.12793' width='8' height='8' rx='7' fill='%23F16722'/%3E%3C/svg%3E");
        }
    }

    .info{display: inline;}
}

.recommendedPlanCard {
    display: flex;
    min-width: 307px;
    max-width: 338px;
    min-height: 621px;
    padding: 12px 3px 3px 3px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 15px;
    border: 3px solid #128292;
    background: #128292;

    .recommendedTitle {
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
    }
}

.recommendedPlanCardNull {
    display: flex;
    min-width: 307px;
    max-width: 338px;
    min-height: 621px;
    padding: 40px 3px 3px 3px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 15px;
    border: 3px solid transparent;
    background: transparent;
}

  
  .clsBtnOrng {
    background-color: #F16722 !important;
    border-radius: 999px;
      &:hover{
        background-color: #D34F0D !important; 
      }
      &:active{
        background-color: #AB400B !important; 
      }
  }