@import '~app/mixins';

.planBg {
  color: $color-grey;
  font-size: 14px;
  position: absolute;
  left: 50px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding-bottom: 10px;
  background-color: #EEF2F6;
}

.container {

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';

  .planContainer {
    border-radius: 12px;
    background-color: #fff;
    display: inline-flex;
    padding: 16px 32px;
    box-sizing: border-box;
    gap: 24px 22px;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    color: #3c4654;

    .currentPlan {
      font-size: 20px;
    }

    .planName {
      font-weight: 500;
      color: #66707D;
    }
  }

  .tabContainer {
    margin: 16px 0px 24px 0px;
    background-color: #fff;
    border: 1px solid #DFE1E4;
    justify-content: flex-start;
    padding: 3px;
    gap: 2px;
    color: #66707d;
    border-radius: 50px;
    display: inline-flex;
    font-family: 'Inter';
    font-size: 16px;
    width: 265px;
    height: 36px;

    button {
      border: none;
      background-color: #fff;
      margin-left: 0px;
    }

    .tab {
      width: 128.5px;
      height: 30px;
      padding: 0px 22px 0px 22px;
      font-weight: 500;
      border-radius: 22px;
    }

    .tab:hover {
      background-color: #dfe1e4;
    }

    .activeTab {
      width: 128.5px;
      height: 30px;
      padding: 0px 22px 0px 22px;
      font-weight: 600;
      border-radius: 22px;
      background-color: #128292;
      color: #fff;
    }
  }
}

.priceContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  min-height: 621px;
  max-height: 708px;
}

.clsPlanReviewPaySec {
  display: flex;
  justify-content: center;
  gap: 48px;
  margin-top: 25px;
  .clsPlansCardsGrp {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
  }
  .clsPlanReviewPayCard1 {
    width: 334px;
    padding: 32px 24px;
    border-radius: 12px;
    background-color: #FFFFFF;
    .clsPlanBtnGrp {
      .clsPlanBtn {
        min-width: 85px;
        height: 20px;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        text-align: center;
        color: #FFFFFF;
        padding: 4px 12px 4px 12px;
        border-radius: 16px;
        background-color: #99A0AA;
        outline: none;
        border: none;
      }
    }
    .clsPlanDetails {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .clsPlanDetailsGrp1 {
        display: flex;
        align-items: center;
        gap: 10px;
        .clsPlanHeading {
          font-size: 24px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          color: #53A6B2;
          margin: 0;
        }
      }
      .clsPlanDetailsGrp2 {
        display: flex;
        gap: 16px;
        .clsBorder {
          border: solid 1px #DFE1E4;
        }
        .clsTxtLine1 {
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
          text-align: left;
          margin: 0;
          color: #66707D;
        }
        .clsTxtLine2 {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          color: #3C4654;
          margin: 0;
          sub {
            font-size: 12px;
            font-weight: 700;
            line-height: 12px;
            text-align: left;
            color: #3C4654;
            bottom: 0;
          }
        }
      }
    }
    .clsPlanIncludedSec {
      border-top: solid 1px #DFE1E4;
      border-bottom: solid 1px #DFE1E4;
      padding: 16px 0;
    }
    .clsPlanAddedOnSec {
      padding-top: 16px;
    }
    .clsPlansList {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .clsPlansItem {
        display: inline-flex;
        align-items: center;
        gap: 6px;
        .clsPlansHeading {
          font-size: 18px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
          color: #3C4654;
          margin: 0;
        }
        .clsPlansCircle {
          width: 8px;
          height: 8px;
          border-radius: 12px;
          background-color: #128292;
          &.clsPlansCircleNon {
            background-color: transparent;
          }
        }
        .clsPlansListName {
          font-size: 14px;
          font-weight: 500;
          line-height: 18.2px;
          text-align: left;
          color: #66707D;
          margin: 0;
        }
      }
    }
  }
  .clsPlanReviewPayCard2 {
    width: 468px;
    padding: 0 48px;
    border-radius: 12px;
    background-color: #FFFFFF;

    .clsPlanReviewHeading {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #3C4654;
      margin: 0;
      padding-top: 48px;
    }

    .clsPlanReviewTimelineContainer {
      padding: 48px 0;
      .clsPlanReviewTimeline {
        position: relative;
        margin: 16px 0;
        .clsPlanTimelineLine {
          position: absolute;
          left: 3px;
          top: 9px;
          bottom: 10px;
          width: 2px;
          background: #128292;
        }

        .clsPlanTimelineItem {
          position: relative;
          padding-left: 20px;
          margin-bottom: 64px;
          &:last-child {
            margin-bottom: 0;
            .clsPlanTimelineDot {
              background: #FFFFFF;
              border: solid 2px #128292;
            }
          }
          .clsPlanTimelineDot {
            position: absolute;
            left: 0;
            top: 5px;
            width: 8px;
            height: 8px;
            background: #128292;
            border-radius: 50%;
          }
          .clsPlanTimelineContent {
            .clsPlanTimelineHead {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 8px;
              .clsPlanTimelineLabel {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                color: #3C4654;
              }
              .clsPlanTimelinePrice {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                color: #3C4654;
              }
            }
            .clsPlanTimelineDescription {
              font-size: 16px;
              font-weight: 500;
              line-height: 19.2px;
              text-align: left;
              color: #66707D;
            }
          }
        }
      }
    }
    .clsPlanReviewPMCard {
      background-color: #EFF2F6;
      padding: 24px;
      border-radius: 16px;
      .clsPlanReviewPMCardHeading {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #3C4654;
        margin-bottom: 16px;
      }
      .clsDropdownCard {
        background-color: #DFE1E4;
        border-radius: 12px;
        padding: 2px 20px 2px 2px;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #BCC1C7;
        }
        &.clsDropdownCardOpen {
          background-color: #128292;
        }
      }
      .clsPayCard {
        background-color: #FFFFFF;
        border-radius: 12px;
        padding: 16px 24px;
        display: inline-flex;
        border: solid 1px #FFFFFF;
        transition: border-color 0.3s ease;
        .clsCustomCheckbox input[type="checkbox"]:checked~& {
          border-color: #128292;
        }
        &.clsPayCardFull {
          display: flex !important;
        }
        .clsMediaTxt1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          color: #3C4654;
          margin-bottom: 0;
        }
        .clsMediaTxt2 {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.8px;
          color: #66707D;
          margin: 0;
        }
      }
    }
    .clsPlanReviewContentPara {
      padding-bottom: 48px;
      .clsPara {
        font-size: 16px;
        font-weight: 500;
        line-height: 20.8px;
        text-align: left;
        color: #3C4654;
        margin: 0;
      }
    }
  }
}

.text_primary {
  color: #128292 !important;
}

.btn_primary {
  padding: 9px 15px;
  background-color: transparent;
  border-radius: 999px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #FFFFFF;
  border: none;
  display: inline-block;
}

.btn_lg {
  padding: 13px 35px;
  border-radius: 999px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #FFFFFF;
}

.btn_xl {
  padding: 13px 35px;
  min-width: 211px;
  min-height: 44px;
  border-radius: 999px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #FFFFFF;
}

.clsBtnPrimaryGreen {
  background-color: #128292 !important;
  border-radius: 999px;
    &:hover{
      background-color: #0E6673 !important; 
    }
    &:active{
      background-color: #0A464F !important; 
    }
}

.clsBtnOrng {
  background-color: #F16722 !important;
  border-radius: 999px;
    &:hover{
      background-color: #D34F0D !important; 
    }
    &:active{
      background-color: #AB400B !important; 
    }
}

.clsInnerFooter {
  padding: 24px;
  background-color: #EFF2F6;
  box-shadow: inset 1px 0 0px 1px #BCC1C7;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    + {
      button {
        margin-left: 24px;
      }
    }
  }
}